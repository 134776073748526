
.content
{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.center-column
{
    /* display: flow-root; */
    /* align-items:baseline; */
    /* background: rgb(179, 41, 41); */
    width: 720px;
    padding: 40px;
}


fieldset
{
    width: '100%';
    margin-bottom: 10;
    padding: 10;
    border: '1px solid #ddd';
    border-radius: 5
}